import React from 'react';
import axios from 'axios';

class ApplicantLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      success: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.get('error') == 'not_authorized') {
      this.setState({ error: 'No tienes permiso para acceder a esa página.' });
    }
  }

  handleChange(event) {
    this.setState({ email: event.target.value });
  }

  handleSubmit(event) {
    this.setState({ error: null, success: null });
    const email = this.state.email;
    if (!email) {
      this.setState({ error: 'Debe ingresar un correo.' });
    } else if (this.validateEmail(email)) {
      axios.get('/api/v1/applicant/generate_login', { headers: { email } })
        .then(res => {
          this.setState({ success: (`Hemos enviado el link de acceso a la siguiente dirección: ${email}`) });
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.setState({ error: 'Este email no se encuentra registrado en KeyVitae.' });
          } else {
            this.setState({ error: error.response.data.messages });
          }
        });
    } else {
      this.setState({ error: 'Debe ingresar un email válido.' });
    }
    event.preventDefault();
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  }

  render() {
    const success = (this.state.success === null ? null : <div className={'alert alert-success horizontal-center'} role="alert"> {this.state.success} </div>);
    const error = (this.state.error === null ? null : <div className={'alert alert-danger horizontal-center'} role="alert"> {this.state.error} </div>);

    return (
      <div className={'screen-container'}>
        <div className={'login-image d-none d-md-block'}>
          <div className={'login-image-text'}>
            <p>Con la Carga Curricular<br/>
            manten actualizado<br/>
            tu CV</p>
          </div>
        </div>
        <div className={'vertical-center horizontal-center'}>
          <div className={'login-form container center-text'}>
            {success}
            {error}
            <h2>Ingreso a Carga <br /> Curricular</h2>
            <p>Revisa que organizaciones han solicitado tu CV<br/>y envíalo en un instante</p>
            <form onSubmit={this.handleSubmit}>
              <div className={'form-group'}>
                <label style={{ float: 'left' }}>Email</label>
                <input className={'form-control'} value={this.state.email} onChange={this.handleChange} placeholder="Ingresa tu email"/>
              </div>
              <button type="submit" className={'btn btn-primary'} style={{ width: '100%' }}>Solicitar Ingreso</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicantLogin;
